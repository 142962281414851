
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Activate',
	data() {
		return { error: '' };
	},
	mounted() {
		setTimeout(() => {
			this.error = '';
			const key: string = this.$route.params.key as string;
			this.$api.users.activate(key)
				.catch((error) => this.error = error)
				.then((res) => {
					if (res.error) {
						this.error = res.error;
					} else if (res.data) {
						this.$router.push({ name: 'Login' });
					} else {
						this.error = 'Unable to activate';
					}
				});
		}, 2000);
	}
});
